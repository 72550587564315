<template lang="pug">
campaign-save
</template>

<script>
import campaignSave from '@/components/campaignSave';
import {mapActions, mapGetters} from 'vuex';
import {GET_AUDIENCE, GET_CAMPAIGN, GET_CAMPAIGN_META, SET_SURFACES} from '@/store/const/campaign';
import loading_mixin from '@/mixins/loading_mixin';

export default {
	name: 'ID',
	mixins: [loading_mixin],
	components: {
		campaignSave,
	},
	computed: {
		...mapGetters('Campaign', ['getCampaign']),
	},
	async mounted() {
		let data = {
			id: this.$route.params.id,
			status: 'all',
			type: 'all',
		};
		
		await this.GET_CAMPAIGN(data);
		if (this.isError(GET_CAMPAIGN)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(GET_CAMPAIGN)
			})
			return;
		}
		if (this.getCampaign.audienceID > 0) {
			await this.GET_AUDIENCE({id: this.getCampaign.audienceID})
		}
		if (this.isError(GET_CAMPAIGN)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(GET_AUDIENCE)
			})
			return;
		}
		await this.GET_CAMPAIGN_META({id: this.$route.params.id})
		if (this.isError(GET_CAMPAIGN_META)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(GET_CAMPAIGN_META)
			})
			return;
		}
		
		await this.SET_SURFACES({id: this.$route.params.id})
		if (this.isError(SET_SURFACES)) {
			this.notify({
				type: 'error',
				text: this.errorMessage(SET_SURFACES)
			})
		}
	},
	methods: {
		...mapActions('Campaign', [GET_CAMPAIGN, GET_AUDIENCE, GET_CAMPAIGN_META, SET_SURFACES])
	}
};
</script>
